// i18next-extract-mark-ns-start products-family-engineering

import { LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Banner from '@components/Banner';
import ContentGrid from '@components/ContentGrid';
import Headline from '@components/Headline';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Breadcrumb } from '@components/Breadcrumbs';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductFamilySystemsPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	const iconIgnite = getImage(
		data.iconIgnite.childImageSharp.gatsbyImageData
	);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Headline
				image={{
					src: data.icon.childImageSharp.gatsbyImageData,
					alt: '',
				}}>
				<Trans i18nKey="headline">
					<p>
						Systems engineering toolset for analysing the behaviour of sub-systems and the impact of design choices on overall system performance.
						Our comprehensive automotive-specific libraries provide in-depth modelling of vehicle sub-systems and their interactions.
					</p>
				</Trans>
			</Headline>

			<ContentGrid>
				<ContentGrid.Item
					image={{
						src: data.gridA.childImageSharp.gatsbyImageData,
						alt: '',
					}}>
					{iconIgnite && (
						<GatsbyImage
							image={iconIgnite}
							alt="IGNITE"
							className="icon-image"
						/>
					)}
					<div className="icon-content">
						<Trans i18nKey="content_ignite">
							<h3>
								IGNITE is a physics-based system simulation package focused on complete vehicle system modelling and simulation.
							</h3>
							<Link
								to={`${LINKS.PRODUCTS}/ignite`}
								className="button--more">
								Find out more
							</Link>
						</Trans>
					</div>
				</ContentGrid.Item>
			</ContentGrid>
		</Page>
	);
};

export default ProductFamilySystemsPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-family-engineering", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/banner-data.jpg" }) {
		...imageBreaker
	}
	icon: file(relativePath: { eq: "content/SystemsEngineering_htext_400x200.png" }) {
		...imageBreaker
	}
	gridA: file(relativePath: { eq: "content/products/ignite/ignite-grid-white.png" }) {
		...imageBreaker
	}
	iconIgnite: file(relativePath: { eq: "content/products/ignite/rs_ignite.png" }) {
		...imageBreaker
	}
}
`;
